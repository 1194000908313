import $ from 'jquery';

import {
  isChrome,
  isFirefox,
  isMac,
  isSafari,
  isWindows,
} from '../legacy/browser';
import { Any, AnyObject } from '../legacy/types';
import './styles.css';

function addEvent(el: Any, eventType: Any, handler: Any): void {
  if (el.addEventListener) {
    // DOM Level 2 browsers
    el.addEventListener(eventType, handler, false);
  } else if (el.attachEvent) {
    // IE <= 8
    el.attachEvent('on' + eventType, handler);
  } else {
    // ancient browsers
    el['on' + eventType] = handler;
  }
}

const msgblue = 'rgb(35,99,195)';
const jlaunch = document.getElementById('jlaunch') as AnyObject;
const demoInstructions = document.getElementById(
  'demoInstructions'
) as AnyObject;

if (isMac || isWindows) {
  // Allow download
  jlaunch.href = `${process.env.JAVA_URL}/jars/launch.jnlp`;
}

function showInstruction(): void {
  let msg = '';

  if (!isMac && !isWindows) {
    // Rather than detect all Linux variants, we will assume they know that Java is supported
    // The goal of this is to notify iOS/Android/ChromeOS users
    $(jlaunch).addClass('disabled');

    msg =
      "<div style='background-color:" +
      msgblue +
      ";padding:7px 5px 7px 5px;color:white;margin:0 auto'>" +
      "<div style='width:80%;margin:0 auto'>" +
      '<ul><li>Java is required to use the Teacher Application. It appears that your device/operating system may not support Java.</li>' +
      '<li>You may still attempt to download the Teacher application if you are sure Java is installed on your device.</li></ul>' +
      '</div>' +
      '</div>' +
      "<div style='margin: 10px auto; text-align: center'><a href='<$= JAVA_URL %>/jars/launch.jnlp' type='application/x-java-jnlp-file' class='btn btn-primary jbbtn'>I have Java</a></div>";
  } else {
    if (isChrome) {
      if (!isMac) {
        msg =
          "<div style='background-color:" +
          msgblue +
          ";padding:7px 5px 7px 5px;color:white;margin:0 auto'>" +
          "<div style='width:80%;margin:0 auto'>" +
          "<ol><li>Choose 'keep' in the bottom left corner of your browser.</li>" +
          "<li>Click on the word 'launch' in the bottom left corner.</li></ol>" +
          '</div>' +
          '</div>';
      } else {
        msg =
          "<div style='background-color:" +
          msgblue +
          ";padding:7px 5px 7px 5px;color:white;margin:0 auto'>" +
          "<div style='width:80%;margin:0 auto'>" +
          "<ol><li>Choose 'Keep' in the bottom left corner of your browser.</li>" +
          '<li>Use Finder to open your Downloads folder.</li>' +
          "<li>Right-click 'launch.jnlp' (note: double-clicking doesn't work).</li>" +
          '<li>Select open.</li></ol>' +
          '</div>' +
          '</div>';
      }
    } else if (isSafari) {
      if (isMac) {
        msg =
          "<div style='background-color:" +
          msgblue +
          ";padding:7px 5px 7px 5px;color:white;margin:0 auto'>" +
          "<p>You won't see anything happen at first.<p><br>" +
          "<div style='width:80%;margin:0 auto'>" +
          '<ol><li>Click on the Safari downloads icon in the top right.</li>' +
          "<li>Right-click 'launch.jnlp'.</li>" +
          "<li>Select 'Show in Finder'.</li>" +
          "<li>Right-click 'launch.jnlp' (note: double-clicking doesn't work)</li>" +
          '<li>Select open.</li></ol>' +
          '</div>' +
          '</div>';
      }
    } else if (isFirefox) {
      if (isMac) {
        msg =
          "<div style='background-color:" +
          msgblue +
          ";padding:7px 5px 7px 5px;color:white;margin:0 auto'>" +
          "<p>Eventually, you'll get a message about not opening a file from the internet.<p><br>" +
          "<div style='width:70%;margin:0 auto'>" +
          '<ol><li>Use Finder to open your Downloads folder.</li>' +
          "<li>Right-click 'launch.jnlp' (note: double-clicking doesn't work).</li>" +
          '<li>Select open.</li></ol>' +
          '</div>' +
          '</div>';
      }
    }
  }

  if (msg.length > 0) {
    demoInstructions.style.display = 'block';
    demoInstructions.innerHTML = msg;
  }
}

addEvent(jlaunch, 'click', showInstruction);
