import { detect } from 'detect-browser';

const browserInfo = detect(navigator.userAgent);

const checkBrowserName = (name: string): boolean => browserInfo?.name === name;
const checkBrowserOS = (os: string): boolean =>
  browserInfo ? !!browserInfo.os?.match(os) : false;

export const isAndroid = checkBrowserName('android');
export const isChrome =
  checkBrowserName('chrome') || checkBrowserName('chrios');
export const isFirefox = checkBrowserName('firefox');
export const isIOS = checkBrowserOS('iOS');
export const isCrios = checkBrowserOS('Crios');
export const isMac = checkBrowserOS('Mac OS');
export const isWindows = checkBrowserOS('Windows');

export const isSafari = checkBrowserName('safari');

export const isIE = checkBrowserName('ie');
export const isEdge = checkBrowserName('edge');

export const isOldIOS = isIOS && parseInt(browserInfo?.version ?? '', 10) < 12;

export const getBrowserName = (): string => {
  let android = '';

  if (isAndroid) {
    android = ' (android)';
  }

  let ios = '';

  if (isIOS) {
    if (['iPad'].indexOf(navigator.userAgent) >= 0) ios = ' (iPad)';
    else if (['iPhone'].indexOf(navigator.userAgent) >= 0) ios = ' (iPhone)';
    else if (['iPod'].indexOf(navigator.userAgent) >= 0) ios = ' (iPod)';
  }

  let ret = 'other';

  if (isChrome) ret = 'Chrome';
  else if (isSafari) ret = 'Safari';
  else if (isFirefox) ret = 'Firefox';
  else if (isCrios) ret = 'Crios';

  return ret + android + ios;
};
